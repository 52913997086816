import {Component, Input, OnInit} from '@angular/core';
import {FileUpload} from "../../../../model/layout.model";
import {first, map, Observable, of, take, tap} from "rxjs";
import {DmxFileService} from "../../../../service/http/dmx-file.service";
import {FileInfo} from "../../../../model/document.model";
import {ObservableWrapper} from "../../../util/observable-wrapper";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileUtil} from "../../../../util/file-util";
import {RfaStore} from "../../../../store/rfa/rfa.store";
import { LoadingErrorComponent } from '../../../common/loading/loading-error/loading-error.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {LayoutElementUtil} from "../../../../service/layout/layout-element.service";

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, MatListModule, NgFor, LoadingErrorComponent, AsyncPipe]
})
export class FileUploadComponent implements OnInit {

  @Input() fileUpload?: FileUpload;
  selectedFiles: File[] = [];
  fileInfosWrapper?: ObservableWrapper<FileInfo[]> | null;

  selectedRfaKuerzel!: string;

  constructor(private fileUploadService: DmxFileService, private rfaStore: RfaStore, private snackbar: MatSnackBar, protected layoutElemeneUtil: LayoutElementUtil) {
    this.rfaStore.getSelectedRfa().pipe(first(), map((rundfunkanstalt) => {
      if (rundfunkanstalt)
        this.selectedRfaKuerzel = rundfunkanstalt.kuerzel
    })).subscribe();
  }

  ngOnInit(): void {
    this.listFiles();
  }

  listFiles() {
    if (this.selectedRfaKuerzel && this.hasFileUploadFiles()) {
      // @ts-ignore
      /*this.fileInfosWrapper = new ObservableWrapper<FileInfo[]>(this.fileUploadService.listFiles(this.selectedRfaKuerzel, this.fileUpload?.files[0].thema)
        .pipe(take(1), tap((fileInfos) => this.setFileUploadFiles(fileInfos))));
      return this.fileInfosWrapper?.data$;*/

      if(this.fileUpload?.files) {
        //console.log("FILES: " + JSON.stringify(this.fileUpload.files));
        const obs: Observable<FileInfo[]> = of(this.fileUpload?.files).pipe(tap((fileInfos) => {
          console.log('tapFileInfos', fileInfos)
          this.setFileUploadFiles(fileInfos)
        }))
        this.fileInfosWrapper = new ObservableWrapper<FileInfo[]>(obs);
        return this.fileInfosWrapper?.data$;;
      }
    }
    return new Observable<FileInfo[]>();
  }

  uploadFiles() {
    if (this.selectedRfaKuerzel && this.selectedFiles.length > 0 && this.fileUpload?.files) {
      this.fileInfosWrapper = new ObservableWrapper<FileInfo[]>(
        this.fileUploadService.upload(this.selectedRfaKuerzel, this.selectedFiles, this.hasFileUploadFiles() ? this.fileUpload?.files[0].thema : null)
          .pipe(tap((fileInfos) => {
            console.log('tapFileInfos', fileInfos)
            this.setFileUploadFiles(fileInfos)
          }))
      );
    }
  }

  deleteFile(file: FileInfo) {
    if (this.selectedRfaKuerzel) {
      this.fileInfosWrapper = null;
      this.fileUploadService.delete(file, this.selectedRfaKuerzel)
        .pipe(map(data => data as string)).subscribe({
        next: message => {
          this.snackbar.open(message, 'OK', {horizontalPosition: "right"});
          this.removeSelectedFileByName(file.fileName);
          if(this.fileUpload?.files) {
            const files: FileInfo[] = this.fileUpload?.files?.filter(fileInfo => {
              fileInfo.fileName !== file.fileName;
            });
            this.fileUpload.files = files;
          }
          //this.listFiles();
        },
        error: (err) => {
          this.snackbar.open('Fehler: Datei konnte nicht gelöscht werden ' + err, 'OK', {horizontalPosition: "right"});
        }
      });
    }
  }

  downloadFile(file: FileInfo) {
    // wenn file.fileName mit .png endet, dann download als image/png, wenn mit .pdf, dann download als application/pdf, wenn mit .jpg, dann download als image/jpg
    let type = 'application/pdf';
    if (file.fileName.endsWith('.png')) {
      type = 'image/png';
    } else if (file.fileName.endsWith('.jpg')) {
      type = 'image/jpg';
    }

    this.fileUploadService.download(this.selectedRfaKuerzel, file.thema, file.fileName, file.persistTime)
      .pipe(take(1))
      .subscribe({
        next: (value) => {
          FileUtil.downloadFile(value, file.fileName, type);
          console.log('File download success')
        },
        error: err => console.error(`Error downloading file ${err}`),
      })
  }

  selectFile(event: any) {
    this.selectedFiles.push(...event.target.files)
    event.target.value = '';
  }

  removeSelectedFile(file: File) {
    this.selectedFiles?.splice(this.selectedFiles?.findIndex(f => f === file), 1)
  }

  private removeSelectedFileByName(fileName: string) {
    this.selectedFiles?.splice(this.selectedFiles?.findIndex(f => f.name === fileName), 1)
  }

  private setFileUploadFiles(fileInfos: FileInfo[]) {
    if (this.fileUpload)
      this.fileUpload.files = fileInfos
  }

  private hasFileUploadFiles() {
    return this.fileUpload?.files && this.fileUpload?.files.length > 0;
  }
}
